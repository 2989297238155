import axios from 'axios';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';
import ProgressiveImage from "react-progressive-image-loading";
import { Link } from 'react-router-dom';
import site from '../api';
import Loader from '../components/Loader';

export default class Industrial extends Component {
    state = {
        categories: [],
        loading: true
    }
    componentDidMount(){
        axios.get(site.URL + '/pro_cat?parent=3').then(
          res => this.setState({categories: res.data, loading: false})
        );
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }
    render() {
        if(this.state.loading === true){
            return (
                <Loader/>
            )
        }
        return (
            <div>
                <Helmet>
                    <title>Industrial Lubricants | Oriental Oil Company Ltd.</title>
                </Helmet>
                <Container>
                    <Row className="cat-head">
                        <Col>
                        <div className="page-title" style={{textAlign: 'left'}}>
                            <h1 style={{fontSize: '55px'}}>Industrial Lubricants</h1>
                        </div>
                        <p>ORIENT Lubricants manufactures and markets a wide range of Lubricants catering to majority of industrial applications. With stringent quality control, robust manufacturing processes and a highly qualified team of Technical Services Engineers, ORIENT Lubricants strives to meet the requirements of all industrial customers. ORIENT Lubricants produces high-end Super Clean hydraulic oils, GL-5 level Gear Oils, along with other products that cater to industries such as steel, cement, equipment used in mining & construction, textile, etc. Through our continuous research and development and working closely with global technology partners, as well as leading machine manufacturers ORIENT strives to keep products up to date and ensure customers with the most reliable and economic solutions. </p>
                        </Col>
                    </Row>
                    <Row>
                    {this.state.categories.map((cat) => {
                        return (
                            <Col md={4} className="product-single" key={cat.id}>
                                <Link to={'/category/' + cat.id}>
                                <ProgressiveImage
                                    preview={cat.acf.image.sizes.thumbnail}
                                    src={cat.acf.image.sizes.medium}
                                    render={(src, style) => <img src={src} style={style}  alt="" />}
                                />
                                <h4>{cat.name}</h4>
                                </Link>
                            </Col>
                        )
                    })}
                    </Row>
                </Container>
            </div>
        )
    }
}
