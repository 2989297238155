import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'
import AboutImg from '../media/about.svg'
import Veedol from '../media/veedol-logo-lp.png'

export default class About extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>About Us | Oriental Oil Company Ltd.</title>
                </Helmet>
                <section className="about-head pad-50">
                    <Container>
                        <Row>
                            <Col md={6} xs={12}>
                                <div className="page-title" style={{textAlign: 'left'}}>
                                    <h1>About Us</h1>
                                </div>
                                <p style={{textAlign: 'justify'}}>Oriental Oil Co. Limited (OOCL) is an ISO 9001:2015 certified company based in Bangladesh. The company was founded in 2002 upon a  simple promise; which was to produce high-end engine lubricants locally for the country at a time when most lubricants were imported and not all local manufacturers were maintaining standards that as on par with imported finished products.  Our state-of-the art facility produces engine oils for both Automotive and Industrial segments spanning a wide range of lubricant products. The present production capacity of the plant is 10,000 MT (single shift). The company has a rich history of past and present associations for toll-blending arrangements with some of the largest multinational companies in the world such as Gulf Oil, Hindustan Petroleum Corporation Limited (HPCL), Veedol International Limited (UK) have strengthened both our technical capabilities, as well as our reputation of almost  two decades for providing the highest quality products meeting American Petroleum Institute (API), as well as Japanese Automotive Standards Organisation (JASO) standards.  <br/> <br/>Known for our reliability with our customers the plant consists of a laboratory with competent technicians who use latest equipment and tests which include; Cold Strip Tarnish test, Aniline Point test, TBN, Pour point, amongst others.    <br/> <br/>ORIENT products are available all over Bangladesh with our extensive network of over 140 distributors and re-sellers.</p>
                            </Col>
                            <Col md={6} xs={12}>
                                <img src={AboutImg} style={{width: '85%'}} alt=""/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '50px'}} className="align-items-center">
                            <Col>
                                <Row style={{marginBottom: '30px'}}>
                                    <Col><h2>Our Associates</h2></Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <img style={{marginBottom: '20px'}} src={Veedol} alt="" />
                                    </Col>
                                    <Col sm={9}>
                                        <p>Oriental Oil Co. Ltd. is also the blender and sole distributor for Veedol International Limited (UK). Veedol is one of the oldest and most historic lubricant brands in the world starting its journey in 1913. Its rich product portfolio and over 100 years of experience in the industry makes Veedol an ideal choice for automotive and industrial clients throughout six continents. </p>
                                        <a href="http://www.veedol.com">www.veedol.com</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
