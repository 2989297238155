import axios from 'axios';
import React, { useState } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import Helmet from 'react-helmet';
import { FiMail, FiNavigation, FiPhoneCall } from 'react-icons/fi';
import { IoLogoFacebook } from "react-icons/io";

const Contact = () => {
    const [name, setName]= useState();
    const [email, setEmail]= useState();
    const [subject, setSubject]= useState();
    const [msg, setMsg]= useState();
    const [cap, setCap]= useState(false);
    const [success, setSuccess]= useState(false);

    const submitForm = (e) => {
        e.preventDefault();
        axios.post("https://orientaloilbd.com/contactform/index.php", {
            name: name,
            email: email,
            subject: subject,
            msg: msg
        }).then(res => {
            setSuccess(true);
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <div className="contact-page">
            <Helmet>
                <title>Contact Us | Oriental Oil Company Ltd.</title>
            </Helmet>
            <Container>
                <Row className="cat-head">
                    <div className="page-title">
                    <h1>Contact</h1>
                    </div>
                </Row>
                <Row>
                    <Col md={6} xs={12}>
                        <div className="contact-header">
                            <h3>Contact Details</h3>
                        </div>
                        <div className="contact-info">
                            <ul>
                                <li><FiMail/> info@orientaloilbd.com</li>
                                <li><FiPhoneCall/> (880-2) 55048284-6</li>
                                <li><IoLogoFacebook/> <a style={{color: '#000', textDecoration: 'underline'}} href="https://facebook.com/orientbangladesh">facebook.com/orientbangladesh</a></li>
                                <li><FiNavigation/> Oriental Oil Company Limited, The Alliance Building <br/> (10th Floor), 63 Pragati Sarani, 10th Floor, Dhaka 1213</li>
                            </ul>
                        </div>
                        <div className="contact-header">
                            <h3>Get In Touch</h3>
                        </div>
                        <div className="contact-form">
                            <Form onSubmit={submitForm}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control placeholder="Enter your full name" required type="text" onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control placeholder="Enter your email address" required type="email" onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control placeholder="Your subject" required type="text" onChange={(e) => setSubject(e.target.value)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control placeholder="Enter your message" required as="textarea" rows="3" onChange={(e) => setMsg(e.target.value)} />
                                </Form.Group>
                                <ReCAPTCHA
                                    sitekey="6LexZNwZAAAAALSdFbwkDGMoHb-4o2uJFKTD4WJu"
                                    onChange={() => setCap(true)}
                                />
                                <br/>
                                {cap ? (
                                    success ? (
                                        <Alert variant="success">
                                            Thank you for contacting us. We'll get back to you soon.
                                        </Alert>
                                    ) : (
                                        <button className="submit-btn" type="submit">
                                            Submit
                                        </button>
                                    )
                                ) : (
                                <button className="submit-btn" type="submit" disabled style={{cursor: "no-drop"}}>
                                    Submit
                                </button>
                                )}
                            </Form>
                        </div>
                    </Col>
                    <Col md={6} xs={12}>
                        <div className="contact-header">
                            <h3>Location</h3>
                        </div>
                        <div className="contact-map">
                            <iframe title="Oriental Oil" width="100%" height="965px" src="https://maps.google.com/maps?width=700&amp;height=500&amp;hl=en&amp;q=Oriental%20Oil+(Oriental%20Oil)&amp;ie=UTF8&amp;t=&amp;z=13&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Contact
