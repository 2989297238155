import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <Container>
                    {/* <div className="footer-social">
                        <ul>
                            <li>
                                <a href="/">
                                    <IconContext.Provider value={{ color: "#8D8D8D", size: '26px' }}>
                                        <IoLogoFacebook />
                                    </IconContext.Provider>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    <div className="footer-copyright text-center">
                        <p>&copy; 2021 Oriental Oil Company Ltd.</p>
                    </div>
                </Container>
            </footer>
        )
    }
}
