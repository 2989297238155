/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Ego from '../media/ego.png';
import Logo from '../media/logo.png';

const Header = () => {
    return (
        <header>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-4">
                        <div className="logo">
                            <Link to="/">
                                <img src={Logo} alt=""/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-8">
                        <Navbar expand="lg">
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto">
                                <NavDropdown title="Lubricants" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/automotive">Automotive Lubricants</NavDropdown.Item>
                                    <NavDropdown.Item href="/industrial">Industrial Lubricants</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/about">About Us</Nav.Link>
                                <Nav.Link href="/contact">Contact</Nav.Link>
                                <Nav.Link href="/recognition">Recognition</Nav.Link>
                                <Nav.Link><Link className="ego-label" to="/category/17"><img style={{width: '80px'}} src={Ego} alt="Ego Logo"/></Link></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>
            {/* <div className="navbar">
                <Container fluid style={{justifyContent: 'center'}}>
                    <Row className="mob-menu">
                        <div className="mob-trigger">
                            <button onClick={MenuClick}>
                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAtNTMgMzg0IDM4NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkPSJtMzY4IDE1NC42Njc5NjloLTM1MmMtOC44MzIwMzEgMC0xNi03LjE2Nzk2OS0xNi0xNnM3LjE2Nzk2OS0xNiAxNi0xNmgzNTJjOC44MzIwMzEgMCAxNiA3LjE2Nzk2OSAxNiAxNnMtNy4xNjc5NjkgMTYtMTYgMTZ6bTAgMCIgZmlsbD0iI2ZmZmZmZiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgc3R5bGU9IiIgY2xhc3M9IiI+PC9wYXRoPjxwYXRoIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZD0ibTM2OCAzMmgtMzUyYy04LjgzMjAzMSAwLTE2LTcuMTY3OTY5LTE2LTE2czcuMTY3OTY5LTE2IDE2LTE2aDM1MmM4LjgzMjAzMSAwIDE2IDcuMTY3OTY5IDE2IDE2cy03LjE2Nzk2OSAxNi0xNiAxNnptMCAwIiBmaWxsPSIjZmZmZmZmIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBzdHlsZT0iIiBjbGFzcz0iIj48L3BhdGg+PHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkPSJtMzY4IDI3Ny4zMzIwMzFoLTM1MmMtOC44MzIwMzEgMC0xNi03LjE2Nzk2OS0xNi0xNnM3LjE2Nzk2OS0xNiAxNi0xNmgzNTJjOC44MzIwMzEgMCAxNiA3LjE2Nzk2OSAxNiAxNnMtNy4xNjc5NjkgMTYtMTYgMTZ6bTAgMCIgZmlsbD0iI2ZmZmZmZiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgc3R5bGU9IiIgY2xhc3M9IiI+PC9wYXRoPjwvZz48L3N2Zz4=" />
                            </button>
                        </div>
                        <div className={menuOpen ? 'mob-menu-content open' : 'mob-menu-content closed'}>
                            <ul>
                                <li><Link onClick={MenuClick} to="/">Home</Link></li>
                                <li><Link onClick={MenuClick} to="/about">About Us</Link></li>
                                <li><Link onClick={MenuClick} to="/automotive">Automotive Lubricants</Link></li>
                                <li><Link onClick={MenuClick} to="/industrial">Industrial Lubricants</Link></li>
                                <li><Link onClick={MenuClick} to="/recognition">Recognition</Link></li>
                                <li><Link onClick={MenuClick} to="/contact">Contact Us</Link></li>
                                <li><Link onClick={MenuClick} className="ego-label" to="/category/17"><img style={{width: '80px'}} src={Ego} alt="Ego Logo"/></Link></li>
                            </ul>
                        </div>
                    </Row>
                    <Row className="desk-menu">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/automotive">Automotive Lubricants</Link></li>
                            <li><Link to="/industrial">Industrial Lubricants</Link></li>
                            <li><Link to="/recognition">Recognition</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                            <li><Link className="ego-label" to="/category/17"><img style={{width: '80px'}} src={Ego} alt="Ego Logo"/></Link></li>
                        </ul>
                    </Row>
                </Container>
            </div> */}
        </header>
    )
}

export default Header