import axios from 'axios';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';
import ProgressiveImage from "react-progressive-image-loading";
import site from '../api';
import Loader from '../components/Loader';

export default class Product extends Component {
    state = {
        product: [],
        loading: true
    }
    componentDidMount(){
        const handle = this.props.match.params.slug;
        axios.get(site.URL + '/products?slug=' + handle).then(
          res => this.setState({product: res.data[0], loading: false})
        );
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }
    render() {
        if(this.state.loading === true){
            return (
                <Loader/>
            )
        }
        const product = this.state.product;
        return (
            <div className="product-wrapper">
                <Helmet>
                    <title>{product.title.rendered} | Oriental Oil Company Ltd.</title>
                </Helmet>
                <Container>
                    <Row style={{padding: '80px 0'}}>
                        <Col md={5}>
                            <ProgressiveImage
                            preview={product.better_featured_image.media_details.sizes.thumbnail.source_url}
                            src={product.better_featured_image.source_url}
                            render={(src, style) => <img src={src} style={style} className="product_image"  alt="" />}
                            />
                        </Col>
                        <Col md={7}>
                            <h2 className="product-title">{product.title.rendered} {product.acf.label}</h2>
                            <div className="product-desc" dangerouslySetInnerHTML={{__html: product.content.rendered}}></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
