import React, { Component } from 'react';
import { Col, Modal } from 'react-bootstrap';
import Helmet from 'react-helmet';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';

export default class Home extends Component {
    state = {
        show: false,
        slider: [
        {
            title: 'UNLEASH YOUR BIKE’S CHARACTER',
            description: 'The best performance bike oil in the market, EGO GOLD 10W-50, FULLY Synthetic (with PAO) along with the rest of the EGO mineral range. Formulated in Italy using the highest standard additives technology.',
            link: '/category/17',
            image: 'https://orientaloilbd.com/wp/wp-content/uploads/2021/07/F-Group-19-Product-Orientation-min-1.png',
        },
        {
            title: 'POWER FLEET 15W-40 & ULTRA 20W-50',
            description: 'The hardest working engines demand the highest quality oil. Tested for the toughest conditions on and off the road. Work harder, work longer with POWER FLEET & ULTRA.',
            link: '/category/5',
            image: 'https://orientaloilbd.com/wp/wp-content/uploads/2021/05/Group-18.png',
        },
        {
            title: 'AUTO MAX CLASSIC & AUTO MAX',
            description: 'For the most economical solution and longer lasting drain intervals, Auto Max & Auto Max Classic for your CNG engine needs.',
            link: '/category/6',
            image: 'https://orientaloilbd.com/wp/wp-content/uploads/2021/05/Group-20.png',
        },
    ]
    }
    componentDidMount() {
        const newsletter = localStorage.getItem('newsletter');
        if(!newsletter){
            this.setState({show: true});
        }
    }
    render() {
        const params = {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            autoplay: {
                delay: 3500,
                disableOnInteraction: false
            },
        }
        const slider = this.state.slider;
        const handleClose = () => {
            this.setState({show: false});
            localStorage.setItem('newsletter', true);
        }
        return (
            <div>
                <Helmet>
                    <title>Oriental Oil Company Ltd.</title>
                </Helmet>
                <Modal 
                show={this.state.show} 
                onHide={handleClose}
                dialogClassName="custom-modal"
                size="lg"
                centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <a href="/category/17"><img src="https://orientaloilbd.com/wp/wp-content/uploads/2021/07/EGO-Gold-Website-Banner_1920_1080-min.png" alt="" /></a>
                    </Modal.Body>
                </Modal>
                <section className="slider-area">
                <Swiper {...params}>
                {slider.map((slide) => {
                    return (
                        <div key={slide.id} className="single-slide">
                            <div className="row align-items-center">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4">
                                    <h1>{slide.title}</h1>
                                    <p>{slide.description}</p>
                                    <Link to={slide.link}>View Products</Link>
                                </div>
                                <div className="col-sm-1"></div>
                                <Col sm={6} sm={{ order: 'last' }} xs={{ order: 'first' }}>
                                    <img src={slide.image} alt="" />
                                </Col>
                            </div>
                        </div>
                    )
                })}
                </Swiper>
                </section>
            </div>
        )
    }
}
