import React from 'react';
import './App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Home from './pages/Home'
import Product from './pages/Product'
import Automotive from './pages/Automotive'
import Industrial from './pages/Industrial'
import Category from './pages/Category'
import Contact from './pages/Contact'
import About from './pages/About'
import Awards from './pages/Awards'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/automotive" component={Automotive} />
        <Route exact path="/Industrial" component={Industrial} />
        <Route exact path="/about" component={About} />
        <Route exact path="/recognition" component={Awards} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/product/:slug" component={Product} />
        <Route exact path="/category/:id" component={Category} />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
