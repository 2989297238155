import axios from 'axios';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ProgressiveImage from "react-progressive-image-loading";
import { Link } from 'react-router-dom';
import site from '../api';
import catExtractor from '../catExtractor';
import Loader from '../components/Loader';
import EgoBanner from '../media/ego-gold-banner.png';

export default class Category extends Component {
    state = {
        products: [],
        loading: true
    }
    componentDidMount(){
        const handle = this.props.match.params.id;
        axios.get(site.URL + '/products?pro_cat=' + handle).then(
          res => this.setState({products: res.data, loading: false})
        );
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }
    render() {
        if(this.state.loading === true){
            return (
                <Loader/>
            )
        }
        return (
            <div style={{paddingBottom: '70px'}}>
                <Container>
                    {this.props.match.params.id == 17 && (
                        <Row>
                            <img src={EgoBanner} alt="Ego Banner" />
                        </Row>
                    )}
                    <Row style={{padding: '35px 0'}}>
                        <Col>
                        <div className="cate-title" style={{textAlign: 'left'}}>
                            <h1>{catExtractor(this.props.match.params.id)}</h1>
                        </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" noGutters={true}>
                    {this.state.products.map((product) => {
                        return (
                            <Col style={{marginBottom: '15px'}} md={3} key={product.id}>
                                <Link className="product-list text-center" to={"/product/" + product.slug}>
                                    <ProgressiveImage
                                    preview={product.better_featured_image.media_details.sizes.thumbnail.source_url}
                                    src={product.better_featured_image.source_url}
                                    render={(src, style) => <img src={src} style={style} className="product_image" alt=""  />}
                                    />
                                    <h4>{product.title.rendered}</h4>
                                    <h6>{product.acf.label}</h6>
                                </Link>
                            </Col>
                        )
                    })}
                    </Row>
                </Container>
            </div>
        )
    }
}
