const catExtractor = (handle) => {
  let result;
  switch (handle){
    case '16':
      result = 'ATF';
      break;
    case '5':
      result = 'Diesel Engine Oil';
      break;
    case '4':
      result = 'Engine Oil';
      break;
    case '6':
      result = 'Gas Engine Oil';
      break;
    case '9':
      result = 'Gear Oil';
      break;
    case '8':
      result = 'Motorcycle Oil';
      break;
    case '17':
      result = 'EGO';
      break;
    case '13':
      result = 'Compressor Oil';
      break;
    case '12':
      result = 'Gear Oil';
      break;
    case '14':
      result = 'Heat Transfer Oil';
      break;
    case '10':
      result = 'Hydraulic Oil';
      break;
    case '11':
      result = 'Machine Oil';
      break;
    case '15':
      result = 'Spindle Oil';
      break;
    default:
      result = 'Unknown'
  }
  return result;
}

export default catExtractor
