import React, { Component } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

export default class Loader extends Component {
    render() {
        return (
            <div className="loader-wrapper">
                <PuffLoader
                size={150}
                color={"#FFDD00"}
                loading={true}
                />
            </div>
        )
    }
}
