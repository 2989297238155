import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';
import ProgressiveImage from "react-progressive-image-loading";
import iso1 from '../media/cert/iso1.jpg';
import iso2 from '../media/cert/iso2.jpg';
import vat1 from '../media/cert/vat1.jpg';
import vat2 from '../media/cert/vat2.jpg';
import vat3 from '../media/cert/vat3.jpg';
import vat4 from '../media/cert/vat4.jpg';

export default class Awards extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Recognition | Oriental Oil Company Ltd.</title>
                </Helmet>
                <Container>
                    <Row className="cat-head">
                        <div className="page-title">
                        <h1>Recognition</h1>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="recog-header" style={{marginTop: '50px', textAlign: 'left', marginBottom: '30px'}}>ISO</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <ProgressiveImage
                                preview={iso1}
                                src={iso1}
                                render={(src, style) => <img src={src} style={style} alt=""  />}
                            />
                        </Col>
                        <Col md={4}>
                            <ProgressiveImage
                                preview={iso2}
                                src={iso2}
                                render={(src, style) => <img src={src} style={style} alt=""  />}
                            />
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="recog-header" style={{marginTop: '50px', textAlign: 'left', marginBottom: '30px'}}>Tax Awards</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <ProgressiveImage
                                preview={vat1}
                                src={vat1}
                                render={(src, style) => <img src={src} style={style} alt=""  />}
                            />
                        </Col>
                        <Col md={3}>
                            <ProgressiveImage
                                preview={vat2}
                                src={vat2}
                                render={(src, style) => <img src={src} style={style} alt=""  />}
                            />
                        </Col>
                        <Col md={3}>
                            <ProgressiveImage
                                preview={vat3}
                                src={vat3}
                                render={(src, style) => <img src={src} style={style} alt=""  />}
                            />
                        </Col>
                        <Col md={3}>
                            <ProgressiveImage
                                preview={vat4}
                                src={vat4}
                                render={(src, style) => <img src={src} style={style} alt="" />}
                            />
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: '60px'}}></Row>
                </Container>
            </div>
        )
    }
}
